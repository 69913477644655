import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import DarkLogo from "../../images/Darklogo.svg";
import { Product_Routes } from "../../data/Routes";

const DesktopLink = ({ to, children, href }) => {
  return (
    <Link
      href={href}
      className="nav-link spacial"
      activeStyle={{
        color: "#055C5A",
        fontFamily: "Avenir-Heavy",
        fontSize: "1.8rem",
      }}
      to={to}
    >
      {children}
    </Link>
  );
};
export function Navbar() {
  const [scrollPosition, setScrollPosition] = useState(0);

  //Scroll Positon
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav className="nav">
      <div
        className="nav-wrapper"
        style={{
          backdropFilter: scrollPosition > 30 ? "blur(2px)" : "blur(0px)",
          backgroundColor:
            scrollPosition > 30
              ? "rgba(255, 255, 253, 1)"
              : "rgba(255, 255, 255, 0)",
          boxShadow:
            scrollPosition > 30 ? "0px 0px 50px rgba(0, 0, 0, 0.5)" : null,
        }}
      >
        <Link to="/">
          <img
            className="company-logo"
            src={DarkLogo}
            alt="Digital Comparison"
          />
        </Link>
        <div className="link-container">
          <DesktopLink to="/">Home</DesktopLink>
          <DesktopLink to={Product_Routes.Aboutus}>About us</DesktopLink>
          <DesktopLink href="#productandservices">Services</DesktopLink>

          <DesktopLink to={Product_Routes.WorkWithUs}>Work with us</DesktopLink>
          <DesktopLink to={Product_Routes.Contactus}>Contact us</DesktopLink>
        </div>
      </div>
      <div className="menu-drop-down">
        <Link className="nav-link" activeClassName="active" to="/">
          Phone
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          Business Solar
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          Business Energy
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          Copier and Printers
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          Digital Web Marketing
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          IT Cyber Security
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          IT Hardware
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          IT Solutions
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          Navigation System
        </Link>
        <Link className="nav-link" activeClassName="active" to="">
          BusinessInternet
        </Link>
      </div>
    </nav>
  );
}
