import React from "react";

export function Heading({ children }) {
  return <h1 className="big-heading">{children}</h1>;
}

export function Paragraph({ children }) {
  return <p className="normal-paragraph">{children}</p>;
}

export function ObliqueText({ children }) {
  return <span className="oblique-text">{children}</span>;
}

export function SpecialTitle({ children }) {
  return <h3 className="special-title">{children}</h3>;
}

export function SectionTitle({ children }) {
  return <h1 className="section-title">{children}</h1>;
}
export function Title({ children }) {
  return <h3 className="title">{children}</h3>;
}
export function CardParagraph({ children }) {
  return <p className="card-paragraph">{children}</p>;
}
