export const Product_Routes = {
  BusinessPhoneSystem: "/phone-system",
  BusinessInternet: "/internet",
  BusinessEnergy: "/energy",
  BusinessSolar: "/solar",
  CopierandPrinter: "/copier-printer",
  DigitalWebMarketing: "/digital-web-marketing",
  CyberSecurity: "/cyber-security",
  ITHardware: "/hardware",
  ITSolutions: "/solutions",
  NavigationSystem: "/navigation",
  Aboutus: "/about-us",
  Contactus: "/contact-us",
  WorkWithUs: "/work-with-us",
  Home: "/",
  Sitemap: "/",
  TermandCondition: "/term-of-service",
  PrivacyPolicy: "/privacy-policy",
  Faccebook: "",
  Twitter: "",
  LinkdId: "",
};

export const Product_Quote_Routes = {
  BusinessPhoneSystem: "/phone-system/Questions",
  BusinessInternet: "/internet/Questions",
  BusinessEnergy: "/energy/Questions",
  BusinessSolar: "/solar/Questions",
  CopierandPrinter: "/copier-printer/Questions",
  DigitalWebMarketing: "/digital-web-marketing/Questions",
  CyberSecurity: "/cyber-security/Questions",
  ITHardware: "/hardware/Questions",
  ITSolutions: "/solutions/Questions",
  NavigationSystem: "/navigation/Questions",
};
