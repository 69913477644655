import React, { useState, useEffect } from "react";
import Burger from "../../svgs/Patterns_Svgs/Burger.svg";
import Cross from "../../svgs/Patterns_Svgs/cross.svg";
import DarkLogo from "../../images/Darklogo.svg";
import { Link } from "gatsby";
import Phone from "../../svgs/Patterns_Svgs/phone-call.svg";
import Mail from "../../svgs/Patterns_Svgs/mail.svg";
import Lightlogo from "../../images/Lightlogo.svg";
import { Product_Routes } from "../../data/Routes";

const MobileLinks = ({ to, children }) => {
  return (
    <Link activeStyle={{ color: "#bffffd" }} to={to}>
      {children}
    </Link>
  );
};

export function MobileNav() {
  const [toggle, setToggle] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  //Menu Toggle
  const menuToggel = () => {
    setToggle(!toggle);
  };

  //Scroll Positon
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="mobile-nav">
      <div
        className="nav-container"
        style={{
          backdropFilter: scrollPosition > 50 ? "blur(15px)" : "blur(0px)",
          backgroundColor:
            scrollPosition > 50
              ? "rgba(255, 255, 253, 1)"
              : "rgba(255, 255, 255, 0)",
          boxShadow:
            scrollPosition > 30 ? "0px 0px 50px rgba(0, 0, 0, 0.5)" : null,
        }}
      >
        <div className="logo">
          <Link to="/">
            <img
              className="burger-icon"
              src={DarkLogo}
              alt="Digital Comparison"
            />
          </Link>
        </div>
        <div onClick={menuToggel} className="burger">
          <img className="burger-icon" src={Burger} alt="open mobile menu" />
        </div>
      </div>
      <div
        style={{ display: toggle ? "block" : "none" }}
        className="menu-overlay"
      >
        <div onClick={menuToggel} className="cross">
          <img className="burger-icon" src={Cross} alt="Close Menu" />
        </div>
        <div className="mobile-links">
          <div className="mobile-footer-logo">
            <Link to="/">
              <img src={Lightlogo} alt="Digital Comparison" />
            </Link>
          </div>
          <div className="mobile-link-container">
            <MobileLinks to={Product_Routes.Home}>Home</MobileLinks>
            <MobileLinks to={Product_Routes.Aboutus}>About us</MobileLinks>
            <MobileLinks to={Product_Routes.WorkWithUs}>
              Work with us
            </MobileLinks>
            <MobileLinks to={Product_Routes.Contactus}>Contact us</MobileLinks>
            <div className="service-container">
              <MobileLinks>Services</MobileLinks>
              <div>
                <MobileLinks to={Product_Routes.BusinessPhoneSystem}>
                  Business Phone System
                </MobileLinks>
                <MobileLinks to={Product_Routes.BusinessEnergy}>
                  Business Energy
                </MobileLinks>
                <MobileLinks to={Product_Routes.BusinessSolar}>
                  Business Solar
                </MobileLinks>
                <MobileLinks to={Product_Routes.BusinessInternet}>
                  Business Internet
                </MobileLinks>
                <MobileLinks to={Product_Routes.ITSolutions}>
                  IT Solutions
                </MobileLinks>
              </div>
              <div>
                <MobileLinks to={Product_Routes.DigitalWebMarketing}>
                  Digital Web Marketing
                </MobileLinks>
                <MobileLinks to={Product_Routes.ITHardware}>
                  IT Hardware
                </MobileLinks>
                <MobileLinks to={Product_Routes.CyberSecurity}>
                  IT Cyber Security
                </MobileLinks>
                <MobileLinks to={Product_Routes.NavigationSystem}>
                  Navigation System
                </MobileLinks>
                <MobileLinks to={Product_Routes.CopierandPrinter}>
                  Copier and Printers
                </MobileLinks>
              </div>
            </div>
            <MobileLinks to={Product_Routes.TermandCondition}>
              Term & Condition
            </MobileLinks>
            <MobileLinks to={Product_Routes.PrivacyPolicy}>
              Privacy Policy
            </MobileLinks>
          </div>
          <div className="mobile-branding-container">
            <div className="mobile-quick-contact">
              <Link>
                <img src={Phone} alt={Phone} />
                <p>03 9457 7300</p>
              </Link>
              <Link>
                <img src={Mail} alt={Mail} />
                <p>info@digitalcomparison.com.au</p>
              </Link>
            </div>
            <div className="mobile-city-names">
              <p>Sydney | Melbourne | Hobart | Brisbane </p>
              <p> Perth | Adelaide | Canberra</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
