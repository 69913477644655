import { Link } from "gatsby";
import React from "react";

export function Button({ children, to, onClick, href }) {
  return (
    <Link href={href} onClick={onClick} to={to} className="primary-button">
      {children}
    </Link>
  );
}
