import React from "react";
import styled from "styled-components";

export function Space({ value }) {
  return <SpaceCreater margin={value}></SpaceCreater>;
}

const SpaceCreater = styled.div`
  margin-top: ${(props) => props.margin || "0"};
`;
