import React from "react";
import styled from "styled-components";

export function IconCover({ children }) {
  return <Cover>{children}</Cover>;
}

const Cover = styled.div`
  width: 6rem;
  height: 6rem;
`;
