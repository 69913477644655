import { Link } from "gatsby";
import React from "react";
import Phone from "../../svgs/Patterns_Svgs/phone-call.svg";
import Mail from "../../svgs/Patterns_Svgs/mail.svg";
import Twitter from "../../svgs/Patterns_Svgs/twitter.svg";
import Facebook from "../../svgs/Patterns_Svgs/facebook.svg";
import Linkedin from "../../svgs/Patterns_Svgs/linkedin.svg";
import Lightlogo from "../../images/Lightlogo.svg";
import { Button } from "./Button";
import { Product_Routes } from "../../data/Routes";

function FooterLink({ children, to }) {
  return (
    <Link activeStyle={{ color: "#bffffd" }} to={to}>
      {children}
    </Link>
  );
}

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="branding-container">
          <div className="footer-logo">
            <Link>
              <img src={Lightlogo} alt="Digital Comparison" />
            </Link>
          </div>
          <div className="quick-contact">
            <Link>
              <img src={Phone} alt={Phone} />
              <p>03 9457 7300</p>
            </Link>
            <Link>
              <img src={Mail} alt={Mail} />
              <p>info@digitalcomparison.com.au</p>
            </Link>
          </div>
          <div className="city-names">
            <p>Sydney | Melbourne | Hobart | Brisbane </p>
            <p> Perth | Adelaide | Canberra</p>
          </div>
        </div>
        <div className="social-container">
          <div className="social-icons">
            <Link to="/">
              <img src={Twitter} alt="twitter" />
            </Link>
            <Link to="/">
              <img src={Facebook} alt="Facebook" />
            </Link>
            <Link to="/">
              <img src={Linkedin} alt="Linkedin" />
            </Link>
          </div>
          <div className="subscribe-input">
            <p>Subscribe to our news letter</p>
            <input placeholder="Enter your email address" />
            <Button to="/">Subscriber</Button>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-main-links">
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to={Product_Routes.Aboutus}>About us</FooterLink>
            <FooterLink to={Product_Routes.Contactus}>Contact us</FooterLink>
            <FooterLink to={Product_Routes.WorkWithUs}>Work with us</FooterLink>
            <FooterLink to={Product_Routes.Sitemap}>Sitemap</FooterLink>
            <FooterLink to={Product_Routes.TermandCondition}>
              Term & Condition
            </FooterLink>
            <FooterLink to={Product_Routes.PrivacyPolicy}>
              Privacy Policy
            </FooterLink>
          </div>
          <div>
            <Link>Services</Link>
            <div className="footer-services-link">
              <div className="services-link-left">
                <FooterLink to={Product_Routes.BusinessPhoneSystem}>
                  Business Phone System
                </FooterLink>
                <FooterLink to={Product_Routes.BusinessSolar}>
                  Business Solar
                </FooterLink>
                <FooterLink to={Product_Routes.BusinessEnergy}>
                  Business Energy
                </FooterLink>
                <FooterLink to={Product_Routes.CopierandPrinter}>
                  Copier and Printers
                </FooterLink>
                <FooterLink to={Product_Routes.DigitalWebMarketing}>
                  Digital Web Marketing
                </FooterLink>
              </div>
              <div className="services-link-right">
                <FooterLink to={Product_Routes.CyberSecurity}>
                  IT Cyber Security
                </FooterLink>
                <FooterLink to={Product_Routes.ITHardware}>
                  IT Hardware
                </FooterLink>
                <FooterLink to={Product_Routes.ITSolutions}>
                  IT Solutions
                </FooterLink>
                <FooterLink to={Product_Routes.NavigationSystem}>
                  Navigation System
                </FooterLink>
                <FooterLink to={Product_Routes.BusinessInternet}>
                  Business Internet
                </FooterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>All right reserved © 2021 DIGITAL COMPARISON</p>
      </div>
    </footer>
  );
}
