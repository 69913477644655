import React from "react";
import { Helmet } from "react-helmet";
import { Footer, MobileNav, Navbar } from "../utility";
import "../../styles/index.scss";

export function Layout({ children }) {
  return (
    <>
      <Helmet>
        {/* Google Tag Manager */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16556389763"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
           {dataLayer.push(arguments)}
           gtag('js', new Date()); gtag('config', 'AW-16556389763');`}
        </script>
        <script>
          {`
            gtag('config', 'AW-16556389763/qxozCIDAw60ZEIPr2dY9', {
              'phone_conversion_number': '03 9457 7300'
            });
          `}
        </script>
        <script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "m0owb8ck6r");
          `}
        </script>
        <script type='text/javascript'>
          {`
            var script = document.createElement('script');
            script.async = true; script.type = 'text/javascript';
            var target = 'https://www.clickcease.com/monitor/stat.js';
            script.src = target; var elem = document.head;
            elem.appendChild(script);
          `}
        </script>
        <noscript>
          {`
            <a href='https://www.clickcease.com' rel='nofollow'><img src='https://monitor.clickcease.com' alt='ClickCease'/></a>
          `}
        </noscript>
        {/* Google Tag Manager */}
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-434014913"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
         gtag('config', 'AW-434014913');`}
        </script> */}
        
        <meta
          name="google-site-verification"
          content="YeUxaaPfaN4riBaqtHdymoo4BNKj2y8MJkp-pcRUxQc"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
             <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1185904185119341&ev=PageView&noscript=1"
          />`,
          }}
        ></script>
      </Helmet>
      <main className="main-container">
        <Navbar />
        <MobileNav />
        {children}
        <Footer />
      </main>
    </>
  );
}